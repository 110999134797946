import {forEach} from "../utils/loop";

export default class CheckboxValidation {
    constructor() {
        this._groups = {};
        this._fields = {};

        this._collectFields();
        this._watch();
    }

    _collectFields() {
        let _this = this;
        let requiredGroups = document.querySelectorAll('.checkbox-group.required');

        for (let i = 0; i < requiredGroups.length; i++) {
            let requiredGroup = requiredGroups[i];

            forEach(requiredGroups, function () {
                let fields = this.querySelectorAll('input[type="checkbox"],input[type="radio"]');

                forEach(fields, function (k) {
                    if (!_this._fields[this.name]) {
                        _this._fields[this.name] = [this];
                        _this._groups[this.name] = [requiredGroup];
                    } else {
                        _this._fields[this.name][k] = this;
                    }
                });
            });
        }

        console.log(_this._fields);
    }

    _watch() {
        let _this = this;

        forEach(_this._fields, function () {
            let container = this;

            forEach(this, function () {
                let field = this;

                field.addEventListener('change', validate);
                validate();

                function validate() {
                    let validFields = 0;

                    forEach(container, function () {
                        if (this.checked) {
                            validFields = 1;
                            return null;
                        }
                    });

                    if (validFields <= 0) {
                        forEach(container, function () {
                            this.setCustomValidity("Invalid field.");
                        });
                    } else {
                        forEach(container, function () {
                            this.setCustomValidity("");
                        });
                    }

                    console.log('changed');
                }
            })
        });
    }

    get fields() {
        return this._fields;
    }

    set fields(value) {
        this._fields = value;
    }

    get groups() {
        return this._groups;
    }
}