import "../scss/app.scss";
import "intl-tel-input/build/css/intlTelInput.min.css";
import $ from "jquery";
import "bootstrap";
import stickybits from "stickybits";
import intlTelInput from "intl-tel-input";
import "jquery-countdown";
import {forEach} from "./utils/loop";
import CheckboxValidation from "./validation/checkbox-validation";

window.$ = window.jQuery = $;

let iti;
let $tel = $('form').find('input[type=tel]');

$tel.each(function (k, v) {
    let $phoneField = $(v);
    let countryChanged = 0;

    iti = intlTelInput(v, {
        initialCountry: "auto",
        separateDialCode: false,
        geoIpLookup: function (success, failure) {
            if (UserCountry) {
                success(UserCountry.iso_code);
            }

            failure();
        }
    });

    this.setAttribute('pattern', '\\+[0-9]{9,}');


    setTimeout(function () {
        if (!countryChanged) {
            $phoneField.trigger('close:countrydropdown');
            countryChanged = !countryChanged;
        }
    }, 1000);

    if ($phoneField.val() === '') {
        $phoneField.on("close:countrydropdown", function () {
            $phoneField.val("+" + iti.getSelectedCountryData().dialCode);
            $phoneField.trigger('paste');
        });
    }
});

$(function () {
    let $body = $('body');
    let header = document.getElementById('header');
    let ajaxDoneCallbacks = {
        "mainForm": function () {

        },
    };
    let ajaxSuccessCallbacks = {
        "googleForm": function () {
            document.location.href = "https://doodlevideo.co/sale";
        }
    };

    stickybits('header:not(.not-fixed)', {useStickyClasses: true});

    new CheckboxValidation();

    $body.on('click', 'a', function (e) {
        let $this = $(this),
            href = $this.attr('href'),
            hashMatch = /\/?(#[A-z\-_]+)/i,
            hashData = href.match(hashMatch),
            $hashResult = $(hashData[1]),
            headerHeight = header.offsetHeight;

        if (hashData.length > 1 && $hashResult.length) {
            e.preventDefault();

            if ($(header).hasClass('not-fixed')) {
                headerHeight = 0;
            }
            window.scrollTo({
                top: $hashResult[0].offsetTop - headerHeight,
                behavior: 'smooth'
            });
        }
    });

    let $form = $('form.doodle-form');

    $form.on('submit', function (e) {
        let $this = $(this);

        if ($this[0].checkValidity()) {

            let $formButton = $this.find('button[type=submit]');

            $formButton.prop('disabled', true);
            $formButton.html('<i class="fas fa-cog fa-spin"></i> Please wait...');

            if (!$this.hasClass('no-ajax')) {
                e.preventDefault();
                let dataType = $this.data('ajax-type') ? $this.data('ajax-type') : 'json';
                let successCallback = $this.data('ajax-success-callback');
                let doneCallback = $this.data('ajax-done-callback');

                $.ajax({
                    url: $this.attr('action'),
                    method: 'POST',
                    data: $this.serialize(),
                    dataType,
                    statusCode: {
                        0: function () {
                            if (ajaxSuccessCallbacks[successCallback]) {
                                ajaxSuccessCallbacks[successCallback].call();
                            }
                        },
                        200: function () {
                            if (ajaxSuccessCallbacks[successCallback]) {
                                ajaxSuccessCallbacks[successCallback].call();
                            }
                        },
                    }
                }).done(function (data) {
                    if (data.success) {
                        window.location.href = '/brief/';
                        $formButton.html('<i class="fas fa-check"></i> Success');
                    }

                    if (ajaxDoneCallbacks[doneCallback]) {
                        ajaxDoneCallbacks[doneCallback].call();
                    }
                });
            }
        } else {
            e.preventDefault();
            e.stopPropagation();
        }

        $this.addClass('was-validated');
    });

    $('.timer-down').countdown("2019/04/29", function (event) {
        $(this).html(
            event.strftime('<span class="block-clock"><span class="line">Days</span>' +
                '<span class="clock-date">%D</span></span>:' +
                '<span class="block-clock">' +
                '<span class="line">Hours</span>' +
                '<span class="clock-hours">%H</span>' +
                '</span>:<span class="block-clock">' +
                '<span class="line">Minutes</span>' +
                '<span class="clock-minutes">%M</span>' +
                '</span>:<span class="block-clock">' +
                '<span class="line">Seconds</span>' +
                '<span class="clock-seconds">%S</span>' +
                '</span>')
        );
    });


    let $modalForm = $('#doodleSaleReplica');

    $('.purchase-block').on('click', '[type=button]', function () {
        let $this = $(this);
        let $form = $this.parents('form');
        let $additions = $form.find('[name=additions]');

        $modalForm.find('[name=product_id]').val($form.find('[name=product_id]').val());
        $modalForm.find('[name=product_price]').val($form.find('[name=product_price]').val());
        $modalForm.find('[name=additions]').each(function (k) {
            $(this).prop('checked', $additions.eq(k).prop('checked'));
        });
    });

    $('iframe[data-src]').each(function () {
        this.setAttribute('src', $(this).data('src'));
    });

});