export let forEach = function (elem, callback) {
    if (Array.isArray(elem)) {
        for (let i = 0; i < elem.length; i++) {
            callback.call(elem[i], i, elem[i]);
        }
    } else {
        for (let a in elem) {
            if (elem.hasOwnProperty(a)) {
                callback.call(elem[a], a, elem[a])
            }
        }
    }
};